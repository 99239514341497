:root {
  --timePerLine: 3.0s;
  --time: 6.0s;
  --lines: 1;
  --lineHeight: 1;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}


.typewriter {
  --bgColor: rgb(243, 244, 246);
  --lines: 500;
  --lineHeight: 1.5rem;
  --timePerLine: 2.5s;
  --widthCh: 22;
  --width: calc(var(--widthCh) * 4ch);
  --time: calc(var(--lines) * var(--timePerLine));
  animation: grow var(--time) steps(var(--lines));
  animation-fill-mode: forwards;
  background: var(--bgColor);  
  line-height: var(--lineHeight);
  max-height: var(--lineHeight);
  overflow: hidden;
  position: relative;
  width: var(--width);
  -ms-overflow-style: none;  
  scrollbar-width: none; 
  word-break: normal;
  text-align: left;
}

.keyframes::before {
  animation: type var(--timePerLine) linear infinite, 
    carriageReturn var(--time) steps(var(--lines)) var(--lines),
    caret 0.5s steps(2) infinite;
}

.typewriter::before {
  content: "";
  animation: type var(--timePerLine) linear infinite, 
    carriageReturn var(--time) steps(var(--lines)) var(--lines),
    caret 0.5s steps(2) infinite;
  background: var(--bgColor);
  border-left: 2px solid;
  bottom: 0;
  height: 2rem;
  position: absolute;
  right: 0;
  width: 100%;
}

.typewriter.no-caret::before {
  border-left-width: 0;
}

.typewriter.big-caret::before {
  border-left-width: 1ch;
}

.typewriter.monospace {
  font-family: monospace, monospace;
}

.typewriter.monospace::before {
  animation: type var(--timePerLine) steps(var(--widthCh)) infinite, 
    carriageReturn var(--time) steps(var(--lines)) var(--lines),
    caret 0.5s steps(2) infinite;
}

@keyframes grow {
  0% { max-height: var(--lineHeight); }
  100% { max-height: calc(var(--lineHeight) * var(--lines)); }
}

@keyframes carriageReturn {
  0% { top: 0; }
  100% { top: calc(var(--lineHeight) * var(--lines)); }
}

@keyframes type {
  0% { width: 100%; }
  100% { width: 0%; }
}

@keyframes caret {
  0% { color: var(--bgColor); }
  100% { color: black; }
}

@keyframes grow {
  0% { max-height: var(--lineHeight); }
  100% { max-height: calc(var(--lineHeight) * var(--lines)); }
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

